const Response = ({ isError = false, messages = [] }) => {
  const src = isError ? '/images/error-icon.png' : '/images/success-icon.png';
  const alt = isError ? 'Error' : 'Success';
  const bgClasses = isError
    ? 'from-[#A02F28] to-[#C74B43]'
    : 'from-[#28A084] to-[#43C780]';
  const sidesClasses = isError ? 'bg-[#BE4038]' : 'bg-[#06BE90]';

  return (
    <div className='relative w-full overflow-hidden rounded-md'>
      <div className='w-full h-full absolute top-0 left-0 z-[-2] bg-[#1A0D17]' />
      <div
        className={`w-full h-full absolute top-0 left-0 z-[-1] opacity-70
          bg-gradient-to-r ${bgClasses}`}
      />
      <div className='lg:px-8 py-4 w-full h-full flex items-center gap-x-5'>
        <div className='w-7 h-7'>
          <img src={src} alt={alt} className='w-full h-full' />
        </div>
        <ul className='w-full flex flex-col [&>*]:mb-1 last:[&>*]:mb-0'>
          {messages.map((msg, i) => (
            <li key={`res-msg-${i}`}>
              <p className='font-light capitalize text-sm lg:text-base'>
                {msg}
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className={`w-[2.5%] h-full absolute top-0 left-0 ${sidesClasses}`}>
        <div
          className={`w-6 h-6 response-clip absolute top-1/2 left-3/4
            translate-y-[-50%] rotate-90 ${sidesClasses}`}
        />
      </div>
      <div
        className={`w-[2.5%] h-full absolute top-0 right-0 rotate-180 ${sidesClasses}`}
      >
        <div
          className={`w-6 h-6 response-clip absolute top-1/2 left-3/4
            translate-y-[-50%] rotate-90 ${sidesClasses}`}
        />
      </div>
    </div>
  );
};

export default Response;
