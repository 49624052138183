import { useGLTF } from '@react-three/drei';

import { SignText } from '.';
import { useLanding } from '../lib';
import { cleanHtml } from '../utils';

const Sign = ({
  position,
  onAbout,
  onServices,
  onGallery,
  onMehaholding,
  onMehanet,
}) => {
  const { nodes, materials } = useGLTF('/models/sign.glb');

  const { data, isError } = useLanding();

  const {
    about_contact_menu,
    my_company_website_menu,
    my_contribution_website_menu,
    my_gallery_menu,
    my_services_menu,
  } = data && data[0] ? data[0] : {};

  const about = cleanHtml(about_contact_menu);
  const companyWebsite = cleanHtml(my_company_website_menu);
  const contributionWebsite = cleanHtml(my_contribution_website_menu);
  const gallery = cleanHtml(my_gallery_menu);
  const services = cleanHtml(my_services_menu);

  return (
    <group position={position} dispose={null}>
      <group
        position={[0.066, 1.33, 0.231]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.498, 0.573, 0.573]}
      >
        <mesh
          geometry={nodes.Plane108.geometry}
          material={materials['wood BLACK']}
        />
        <mesh geometry={nodes.Plane108_1.geometry} material={materials.wood} />
      </group>
      <mesh
        geometry={nodes.Plane051.geometry}
        material={materials.PaletteMaterial001}
        position={[0.176, 1.883, 0.202]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={[0.498, 0.573, 0.573]}
      />
      <mesh
        geometry={nodes.Circle013.geometry}
        material={materials.PaletteMaterial002}
        position={[-27.697, -0.155, -27.333]}
      />
      {!isError && (
        <group>
          <SignText
            fontSize={0.21}
            position={[0.09, 4.25, 0.24]}
            onClick={onAbout}
          >
            My CV
          </SignText>
          <SignText
            fontSize={0.15}
            position={[0.05, 3.55, 0.225]}
            onClick={onAbout}
          >
            {about}
          </SignText>
          <SignText
            fontSize={0.125}
            position={[0.35, 3, 0.212]}
            onClick={onMehanet}
          >
            {companyWebsite}
          </SignText>
          <SignText
            fontSize={0.125}
            position={[-0.2, 2.45, 0.25]}
            onClick={onMehaholding}
          >
            {contributionWebsite}
          </SignText>
          <SignText
            fontSize={0.16}
            position={[0.3, 1.9, 0.275]}
            onClick={onGallery}
          >
            {gallery}
          </SignText>
          <SignText
            fontSize={0.175}
            position={[0.2, 1.35, 0.225]}
            onClick={onServices}
          >
            {services}
          </SignText>
        </group>
      )}
    </group>
  );
};

export default Sign;

useGLTF.preload('/models/sign.glb');
