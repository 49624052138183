import { useEffect, useState, memo } from 'react';

import { Projector } from '.';
import { projectors } from '../constants';
import { useRocket } from '../context';

const Projectors = () => {
  const [on, setOn] = useState(false);
  const { isLaunching, isLanding } = useRocket();

  useEffect(() => {
    if (!isLaunching && !isLanding) return setOn(true);

    const interval = setInterval(() => setOn((on) => !on), 1000);
    return () => clearInterval(interval);
  }, [isLaunching, isLanding]);

  return (
    <group>
      {projectors.map(({ position, rotationY }, i) => (
        <Projector
          key={`projector-${i}`}
          position={position}
          rotation-y={rotationY}
          on={on}
        />
      ))}
    </group>
  );
};

export default memo(Projectors);
