import { EffectComposer, Bloom } from '@react-three/postprocessing';

import { useTheme } from '../../context';

const PostProcessing = () => {
  const { theme } = useTheme();

  if (process.env.NODE_ENV === 'development') return;
  return (
    <EffectComposer>
      <Bloom
        mipmapBlur
        luminanceThreshold={1}
        intensity={theme === 'dark' ? 0.8 : 0.45}
      />
    </EffectComposer>
  );
};

export default PostProcessing;
