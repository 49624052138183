import { useEffect, useState } from 'react';

const useDomReady = () => {
  const [isDomReady, setIsDomReady] = useState(false);

  useEffect(() => {
    setIsDomReady(true);
  }, []);

  return isDomReady;
};

export default useDomReady;
