import { useGLTF } from '@react-three/drei';

const GroundLight = (props) => {
  const { nodes, materials } = useGLTF('/models/ground-light.glb');

  return (
    <group {...props} dispose={null}>
      <group rotation={[0, -0.49, 0]} scale={0.463}>
        <mesh
          geometry={nodes.Circle017.geometry}
          material={materials['BLACK PLASTIC']}
        />
        <mesh geometry={nodes.Circle017_1.geometry}>
          <meshPhongMaterial
            emissive={props.on ? 'red' : 'gray'}
            emissiveIntensity={props.on ? 20 : 0}
            toneMapped={false}
          />
        </mesh>
      </group>
    </group>
  );
};

export default GroundLight;

useGLTF.preload('/models/ground-light.glb');
