import { createContext, useContext, useState } from 'react';

const rocketContext = createContext({
  isLaunching: false,
  isLanding: false,
  hasLanded: false,
  setLaunching: () => {},
  setLanding: () => {},
  setLanded: () => {},
});

const RocketProvider = ({ children }) => {
  const [isLaunching, setIsLaunching] = useState(false);
  const [isLanding, setIsLanding] = useState(false);
  const [hasLanded, setHasLanded] = useState(false);

  return (
    <rocketContext.Provider
      value={{
        isLaunching,
        isLanding,
        hasLanded,
        setLaunching: setIsLaunching,
        setLanding: setIsLanding,
        setLanded: setHasLanded,
      }}
    >
      {children}
    </rocketContext.Provider>
  );
};

const useRocket = () => useContext(rocketContext);

export { rocketContext, RocketProvider, useRocket };
