const ENDPOINT = 'https://api.mr-zz.com';
const OS_ENDPOINT = 'https://os.mr-zz.com';

const progressSentences = [
  'Initializing program (0%)',
  'Downloading repository (7%)',
  'Installing drivers (19%)',
  'Compressing GLB files (32%)',
  'Transpiling GLB to JSX (45%)',
  'Rendering 3D models (51%)',
  'Setting up website stage (69%)',
  'Adding lights and controls (73%)',
  'Applying postprocessing (78%)',
  'Adjusting resolution (84%)',
  'Finalizing settings (99%)',
  'Complete (100%)',
];

const pointLights = [
  { position: [-5, 9, 0], nightIntensity: 60, dayIntensity: 30 },
  {
    position: [26, 6, 25],
    nightIntensity: 160,
    dayIntensity: 30,
  },
];

const groundLights = [
  [-5, 0, -1.75],
  [0, 0, -7],
  [-10, 0, -6],
];

const projectors = [
  {
    position: [-1, 0, -2],
    rotationY: -Math.PI * 0.35,
  },
  { position: [-8, 0, -3], rotationY: Math.PI },
  { position: [0, 0, -10], rotationY: 0 },
];

export {
  progressSentences,
  pointLights,
  groundLights,
  projectors,
  ENDPOINT,
  OS_ENDPOINT,
};
