import { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
import { animated as a, useSpring, easings } from '@react-spring/three';
import { useMediaQuery } from 'react-responsive';

import {
  Lights,
  RocketLaunchCamera,
  PostProcessing,
  Sky,
  BackButton,
  Controller,
} from './components';
import { Intro, Wrapper } from '../components';
import {
  Person,
  Server,
  World,
  Rocket,
  RocketArms,
  Fan,
  Globe,
  Sign,
  Monitors,
  Radars,
  GroundLights,
  Projectors,
  DeskLight,
  AntenaLight,
  Gate,
} from '../models';
import { useIntro } from '../context';
import { adjustCamera } from '../utils';

const AnimatedCamera = a(PerspectiveCamera);
const AnimatedControls = a(OrbitControls);

const Experience = ({
  isMobileOrTablet,
  onAbout,
  onServices,
  onGallery,
  onMehaholding,
  onMehanet,
  cameraRef,
  controlsRef,
  cameraSpring,
  controlsSpring,
}) => {
  return (
    <>
      <Sky />
      <Lights />
      <AnimatedControls
        ref={controlsRef}
        minDistance={20}
        maxDistance={40}
        minPolarAngle={-Math.PI * 0.5}
        maxPolarAngle={Math.PI * 0.475}
        enablePan={false}
        {...controlsSpring}
      />
      <AnimatedCamera
        makeDefault
        ref={cameraRef}
        fov={isMobileOrTablet ? 80 : 65}
        {...cameraSpring}
      />
      <group>
        <World />
        <Gate />
        <Sign
          position={[31, 0, 22]}
          onAbout={onAbout}
          onServices={onServices}
          onGallery={onGallery}
          onMehaholding={onMehaholding}
          onMehanet={onMehanet}
        />
        <Person position={[17.6, 0.65, 26.8]} rotation-y={-Math.PI * 0.075} />
        <group position={[14.38, 0, 31.78]} rotation-y={Math.PI * 0.5}>
          <Server />
          <group rotation-y={-Math.PI * 0.5}>
            <Fan position={[-1.29, 3.35, 0.35]} rotation-y={-Math.PI * 0.05} />
          </group>
        </group>
        <group position={[21.6, 2.55, 24.15]} rotation-z={Math.PI * 0.15}>
          <Globe />
        </group>
        <group position={[-4.5, 0, -5.9]}>
          <RocketArms
            scale={1}
            position={[0, 9.4, -0.2]}
            rotation-y={Math.PI}
          />
          <Rocket />
        </group>
        <Monitors />
        <Radars />
        <GroundLights />
        <Projectors />
        <DeskLight position={[19.2, 1.45, 24.5]} />
        <AntenaLight position={[-4.65, 16.5, -9.8]} scale={0.25} />
      </group>
    </>
  );
};

const Home = () => {
  const { isRemoved } = useIntro();
  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });

  const cameraRef = useRef();
  const controlsRef = useRef();

  const [cameraSpring, cameraApi] = useSpring(() => ({
    from: { position: [21.2, 4.2, 31.2] },
    config: { duration: 3000, easing: easings.easeOutQuad },
  }));

  const [controlsSpring, controlsApi] = useSpring(() => ({
    from: {
      target: [0, 0, 0],
    },
    config: { duration: 3000, easing: easings.easeOutQuad },
  }));

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    if (!isRemoved) return;

    cameraApi.start({
      to: {
        position: [
          isMobileOrTablet ? 33 : 32,
          isMobileOrTablet ? 3.35 : 3.5,
          26,
        ],
      },
      delay: 2500,
    });
    controlsApi.start({
      to: {
        target: [isMobileOrTablet ? 20 : 12, 0, 5],
      },
      delay: 2500,
    });
  }, [isRemoved, cameraApi, controlsApi]);

  const handleAbout = () =>
    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [
        isMobileOrTablet ? 17.2 : 16.8,
        isMobileOrTablet ? 2.9 : 2.5,
        isMobileOrTablet ? 27.3 : 26.7,
      ],
      [0, 0, isMobileOrTablet ? 6.8 : 10]
    );

  const handleServices = () =>
    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [isMobileOrTablet ? 18.4 : 18, 2.3, isMobileOrTablet ? 26.6 : 25.5],
      [isMobileOrTablet ? 6.3 : 5, 0, isMobileOrTablet ? -1.5 : 0]
    );

  const handleGallery = () =>
    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [isMobileOrTablet ? 18.1 : 17.3, 3.7, isMobileOrTablet ? 31.6 : 31.5],
      [-10, isMobileOrTablet ? 1.5 : 0.5, 30]
    );

  const handleMehaholding = () =>
    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [isMobileOrTablet ? 16.3 : 15.7, 2.7, 28.35],
      [-17.8, -2, 36.6]
    );

  const handleMehanet = () =>
    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [20, 2.3, 25],
      [32.5, 0, isMobileOrTablet ? -1.5 : -5]
    );

  const handleBack = () =>
    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [isMobileOrTablet ? 33 : 32, isMobileOrTablet ? 3.35 : 3.5, 26],
      [isMobileOrTablet ? 20 : 12, 0, 5],
      false
    );

  return (
    <Wrapper id='home-wrapper'>
      <BackButton onClick={handleBack} />
      <Controller onContact={handleAbout} onOrder={handleServices} />
      <Intro>
        <Canvas>
          <RocketLaunchCamera
            cameraRef={cameraRef}
            controlsRef={controlsRef}
            cameraApi={cameraApi}
            controlsApi={controlsApi}
          />
          <Experience
            isMobileOrTablet={isMobileOrTablet}
            onAbout={handleAbout}
            onServices={handleServices}
            onGallery={handleGallery}
            onMehaholding={handleMehaholding}
            onMehanet={handleMehanet}
            cameraRef={cameraRef}
            controlsRef={controlsRef}
            cameraSpring={cameraSpring}
            controlsSpring={controlsSpring}
          />
          <PostProcessing />
        </Canvas>
      </Intro>
    </Wrapper>
  );
};

export default Home;
