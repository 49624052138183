import {
  CenterMonitor,
  ServerMonitor,
  RadarMonitor,
  RocketMonitorOne,
  RocketMonitorTwo,
  GlobeMonitor,
} from '.';

const Monitors = () => {
  return (
    <>
      <CenterMonitor
        position={[15.8, 1.6, 25.55]}
        rotation-y={-Math.PI * 0.75}
      />
      <CenterMonitor
        position={[17.5, 1.6, 24.4]}
        rotation-y={-Math.PI * 0.85}
        path='services'
      />
      <ServerMonitor position={[14.5, 2, 31.5]} rotation-y={Math.PI * 0.5} />
      <RadarMonitor
        position={[14.4, 1.8, 28.7]}
        rotation-y={-Math.PI * 0.4}
        scale={1.1}
      />
      <RocketMonitorOne position={[-8.8, 1.5, -16.25]} />
      <RocketMonitorOne position={[-3, 10.5, -10.8]} />
      <RocketMonitorOne
        position={[-5.7, 10.5, -11.1]}
        rotation-y={Math.PI * 0.52}
      />
      <RocketMonitorTwo position={[-3, 6.5, -11.1]} />
      <GlobeMonitor
        position={[20.55, 2.11, 23.85]}
        rotation-y={-Math.PI * 0.125}
      />
    </>
  );
};

export default Monitors;
