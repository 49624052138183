import { useGLTF } from '@react-three/drei';

const Projector = (props) => {
  const { nodes, materials } = useGLTF('/models/projector.glb');

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI, 1.165, -Math.PI]} scale={0.486}>
        <mesh
          geometry={nodes.Circle033.geometry}
          material={materials['BLACK PLASTIC.002']}
        />
        <mesh
          geometry={nodes.Circle033_1.geometry}
          material={materials['camera white']}
        />
        <mesh geometry={nodes.Circle033_2.geometry}>
          <meshPhongMaterial
            emissive={props.on ? 'white' : 'gray'}
            emissiveIntensity={props.on ? 15 : 0}
            toneMapped={false}
          />
        </mesh>
      </group>
    </group>
  );
};

export default Projector;

useGLTF.preload('/models/projector.glb');
