import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useRocket } from '../../context';
import { adjustCamera } from '../../utils';

const RocketLaunchCamera = ({
  cameraApi,
  controlsApi,
  cameraRef,
  controlsRef,
}) => {
  const { isLaunching } = useRocket();
  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (!isLaunching) return;

    adjustCamera(
      cameraApi,
      controlsApi,
      cameraRef,
      controlsRef,
      [20, 15, isMobileOrTablet ? 33 : 28],
      [0, 12, -2.5]
    );
  }, [
    isLaunching,
    isMobileOrTablet,
    controlsApi,
    cameraApi,
    cameraRef,
    controlsRef,
  ]);

  return null;
};

export default RocketLaunchCamera;
