import { createContext, useState, useEffect, useContext } from 'react';

const themeContext = createContext({
  theme: 'dark',
  toggleTheme: () => {},
});

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const storagedTheme = localStorage.getItem('theme');
    if (storagedTheme) return setTheme(storagedTheme);

    localStorage.setItem('theme', 'dark');
  }, [setTheme]);

  const handleThemeToggle = () =>
    setTheme(() => {
      if (theme === 'dark') {
        localStorage.setItem('theme', 'light');
        return 'light';
      }

      localStorage.setItem('theme', 'dark');
      return 'dark';
    });

  return (
    <themeContext.Provider
      value={{
        theme,
        toggleTheme: handleThemeToggle,
      }}
    >
      {children}
    </themeContext.Provider>
  );
};

const useTheme = () => useContext(themeContext);

export { themeContext, ThemeProvider, useTheme };
