import { useEffect } from 'react';
import { useRef } from 'react';

const Wrapper = ({ children, id }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const wrapper = wrapperRef.current;

    const handleHeightAdjustment = () => {
      if (window.innerHeight % 2 === 0) {
        wrapper.style.height = `${window.innerHeight}px`;
      } else {
        wrapper.style.height = `${window.innerHeight - 1}px`;
      }
    };
    handleHeightAdjustment();

    window.addEventListener('resize', handleHeightAdjustment);
    return () => window.removeEventListener('resize', handleHeightAdjustment);
  });

  return (
    <div id={id} ref={wrapperRef} className='w-full relative'>
      {children}
    </div>
  );
};

export default Wrapper;
