import { useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { animated as a, useSpring } from '@react-spring/three';

import { useRocket } from '../context';

const RocketArms = (props) => {
  const { nodes } = useGLTF('/models/rocket-arm.glb');

  const { isLaunching, hasLanded } = useRocket();

  const [springOne, apiOne] = useSpring(() => ({
    from: { position: [0, 0, 0], 'rotation-y': 0 },
  }));

  const [springTwo, apiTwo] = useSpring(() => ({
    from: { position: [0, 0, 0], 'rotation-y': 0 },
  }));

  useEffect(() => {
    if (!isLaunching) return;

    apiOne.start({
      to: { position: [-1, 0, 0.75], 'rotation-y': Math.PI * 0.25 },
      config: { duration: 2500 },
      delay: 2500,
    });

    apiTwo.start({
      to: { position: [1, 0, 0.75], 'rotation-y': -Math.PI * 0.25 },
      config: { duration: 2500 },
      delay: 2500,
    });
  }, [isLaunching, apiOne, apiTwo]);

  useEffect(() => {
    if (!hasLanded) return;

    apiOne.start({
      to: { position: [0, 0, 0], 'rotation-y': 0 },
      config: { duration: 2000 },
    });

    apiTwo.start({
      to: { position: [0, 0, 0], 'rotation-y': 0 },
      config: { duration: 2000 },
    });
  }, [hasLanded, apiOne, apiTwo]);

  return (
    <group {...props}>
      <a.group {...springOne}>
        <mesh
          geometry={nodes.Circle001.geometry}
          material={nodes.Circle001.material}
          scale={1.671}
        />
      </a.group>
      <a.group {...springTwo}>
        <mesh
          geometry={nodes.Circle001.geometry}
          material={nodes.Circle001.material}
          scale={1.671}
          position={[0, 0, 0]}
          rotation-z={Math.PI}
        />
      </a.group>
    </group>
  );
};

export default RocketArms;

useGLTF.preload('/models/rocket-arm.glb');
