import { useGLTF } from '@react-three/drei';

const World = (props) => {
  const { nodes, materials } = useGLTF('/models/world.glb');

  return (
    <group {...props} dispose={null}>
      <group name='Scene'>
        <mesh
          name='Plane'
          geometry={nodes.Plane.geometry}
          material={materials.PaletteMaterial001}
          position={[-4.64, 0, -9.752]}
          rotation={[Math.PI, 0, Math.PI]}
        />
        <group name='Plane004' scale={-16.193}>
          <mesh
            name='Plane005'
            geometry={nodes.Plane005.geometry}
            material={materials['Material.016']}
          />
          <mesh
            name='Plane005_1'
            geometry={nodes.Plane005_1.geometry}
            material={materials['Material.020']}
          />
          <mesh
            name='Plane005_2'
            geometry={nodes.Plane005_2.geometry}
            material={materials.PaletteMaterial002}
          />
        </group>
        <mesh
          name='Circle003'
          geometry={nodes.Circle003.geometry}
          material={materials.PaletteMaterial003}
          position={[17.038, 0.857, 25.739]}
          rotation={[0, -0.997, 0]}
          scale={4.172}
        />
        <mesh
          name='Circle010'
          geometry={nodes.Circle010.geometry}
          material={materials.MONITOR}
          position={[16.605, 1.459, 25.071]}
          rotation={[0, -0.997, 0]}
          scale={4.172}
        />
        <mesh
          name='Plane077'
          geometry={nodes.Plane077.geometry}
          material={materials['light -  radar']}
          position={[16.865, 1.305, 25.757]}
          rotation={[Math.PI, -0.594, Math.PI]}
        />
        <group
          name='Plane084'
          position={[15.446, 1.2, 26.932]}
          rotation={[Math.PI, -0.572, Math.PI]}
        >
          <mesh
            name='Plane057'
            geometry={nodes.Plane057.geometry}
            material={materials.PaletteMaterial004}
          />
          <mesh
            name='Plane057_1'
            geometry={nodes.Plane057_1.geometry}
            material={materials.PaletteMaterial005}
          />
        </group>
        <mesh
          name='Circle011'
          geometry={nodes.Circle011.geometry}
          material={nodes.Circle011.material}
          position={[13.906, 1.209, 27.765]}
          rotation={[0, -1.353, 0]}
          scale={0.203}
        />
        <group
          name='Plane024'
          position={[-0.635, -0.021, 11.753]}
          rotation={[0, -0.37, 0]}
          scale={0.304}
        >
          <mesh
            name='Plane021'
            geometry={nodes.Plane021.geometry}
            material={materials.PaletteMaterial006}
          />
          <mesh
            name='Plane021_1'
            geometry={nodes.Plane021_1.geometry}
            material={materials.PaletteMaterial007}
          />
        </group>
        <mesh
          name='Plane038'
          geometry={nodes.Plane038.geometry}
          material={materials.PaletteMaterial008}
          position={[18.861, 0, 28.487]}
          rotation={[0, 0.574, 0]}
          scale={6.322}
          material-opacity={0.25}
        />
      </group>
    </group>
  );
};

export default World;

useGLTF.preload('/models/world.glb');
