import { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useSpring, animated as a } from '@react-spring/three';

import { useRocket } from '../context';

const Gate = (props) => {
  const { nodes, materials } = useGLTF('/models/gate.glb');

  const ref = useRef(null);
  const { isLaunching, hasLanded } = useRocket();

  const [spring, api] = useSpring(() => ({
    from: { position: [-2.5, 4.7, 6.25], 'rotation-z': Math.PI * 0.5 },
    config: { duration: 6000 },
    delay: 6000,
  }));

  useEffect(() => {
    if (!isLaunching) return;

    api.start({
      to: { position: [-1.35, 0, 6.25], 'rotation-z': 0 },
    });
  }, [isLaunching, api]);

  useEffect(() => {
    if (!ref.current) return;
    if (!hasLanded) return;

    api.start({
      to: { position: [-2.5, 4.7, 6.25], 'rotation-z': Math.PI * 0.5 },
    });
  }, [hasLanded, api]);

  return (
    <a.group
      ref={ref}
      {...props}
      {...spring}
      dispose={null}
      rotation-y={Math.PI * 0.12}
    >
      <group position={[1.731, 0, 0]}>
        <mesh
          geometry={nodes.Plane001_1.geometry}
          material={materials['metal - orange']}
        />
        <mesh
          geometry={nodes.Plane001_2.geometry}
          material={materials['metal - white']}
        />
      </group>
    </a.group>
  );
};

export default Gate;

useGLTF.preload('/models/gate.glb');
