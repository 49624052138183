import ReactHowler from 'react-howler';

import { Header } from './components';
import { useSound } from './context';
import HomePage from './home';

const App = () => {
  const { isMute } = useSound();

  return (
    <>
      <ReactHowler src='/sounds/main.mp3' volume={1} playing={!isMute} />
      <Header />
      <HomePage />
    </>
  );
};

export default App;
