import { createPortal } from 'react-dom';
import { forwardRef, useState } from 'react';
import { X } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';

import { Response } from '../../components';
import { mutateLaunch } from '../../lib';
import { useDomReady } from '../../hooks';
import { useRocket } from '../../context';

const LaunchForm = forwardRef(({ onClose }, ref) => {
  const isDomReady = useDomReady();

  const { setLaunching, isLanding, isLaunching } = useRocket();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');

  const [err, setErr] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: () => mutateLaunch(name, email, mobile),
    onSuccess: async (data) => {
      const res = await data.json();
      if (res.errors) return setErr(res.errors);

      onClose();
      setLaunching(true);
    },
    onError: ({ message }) => setErr([message]),
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setErr(null);
    mutate();
  };

  const pendingText = isPending ? 'ignition...' : 'start engine';
  const buttonText =
    isLaunching || isLanding ? 'wait until rocket lands...' : pendingText;

  if (!isDomReady) return null;
  return createPortal(
    <form
      ref={ref}
      className='fixed top-1/2 left-1/2 z-[101] w-[95%] md:w-3/4 lg:w-1/2 rounded-md
    bg-primary text-white font-kode py-6 px-4 flex flex-col items-center shadow-xl'
      onSubmit={handleSubmit}
    >
      <button
        className='absolute top-0 right-[5%] bg-primary rounded-full 
        w-14 h-14 translate-y-[-50%] flex justify-center items-center'
        onClick={onClose}
      >
        <X className='w-8 h-8' />
      </button>
      <div className='text-center mb-5'>
        <h1 className='lg:text-xl font-semibold border-b-2 pb-1'>
          MRZ Launch System
        </h1>
        <p className='text-xs pt-1'>
          launch the <span className='text-base'>&#128640;</span> by joining our
          newsletter!
        </p>
      </div>
      <div className='w-full lg:w-3/4'>
        <label htmlFor='launch-name' className='text-sm lg:text-base'>
          Your Name:
        </label>
        <input
          id='launch-name'
          type='text'
          placeholder='John Doe'
          className='w-full mt-1 bg-primary-light rounded-sm py-1 px-3 lg:py-2 lg:px-4'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className='w-full lg:w-3/4 my-5'>
        <label htmlFor='launch-email' className='text-sm lg:text-base'>
          Your Email:
        </label>
        <input
          id='launch-email'
          type='email'
          placeholder='johndoe@example.com'
          className='w-full mt-1 bg-primary-light rounded-sm py-1 px-3 lg:py-2 lg:px-4'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className='w-full lg:w-3/4'>
        <label htmlFor='launch-mobile' className='text-sm lg:text-base'>
          Your Phone Number:
        </label>
        <input
          id='launch-mobile'
          type='number'
          placeholder='0123456789'
          className='w-full mt-1 bg-primary-light rounded-sm py-1 px-3 lg:py-2 lg:px-4'
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
      </div>
      <button
        className='my-5 bg-secondary px-3 py-1 text-sm lg:text-base rounded-sm
      hover:bg-secondary-light duration-100 disabled:bg-gray-400 disabled:cursor-not-allowed'
        disabled={isPending || isLaunching || isLanding}
      >
        {buttonText}
      </button>
      {err && <Response isError messages={err} />}
    </form>,
    document.getElementById('home-wrapper')
  );
});

export default LaunchForm;
