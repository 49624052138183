import { Suspense, useState, useEffect } from 'react';
import { useProgress } from '@react-three/drei';
import { TypeAnimation } from 'react-type-animation';
import { animated as a, useTransition } from '@react-spring/web';

import { progressSentences } from '../constants';
import { useIntro } from '../context';

const SPEED = 230;
const COMMAND = 'mrz-pkg run start';

const Intro = ({ children }) => {
  const { progress } = useProgress();
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(process.env.NODE_ENV === 'production');
  const { setRemoved } = useIntro();

  const transition = useTransition(show, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
    onDestroyed: setRemoved,
  });

  useEffect(() => {
    if (!show) return;

    const interval = setInterval(() => setIndex((i) => i + 1), SPEED);
    return () => clearInterval(interval);
  }, [show]);

  return (
    <>
      <Suspense fallback={null}>{children}</Suspense>
      {transition(
        (style, item) =>
          item && (
            <a.div
              style={style}
              className='w-full h-screen fixed top-0 left-0 
            text-white font-kode py-4 px-1 bg-black z-[2000]'
            >
              <div className='mb-4'>
                <h1 className='font-bold lg:text-xl mb-1 lg:mb-[.25rem]'>
                  MRZ OS (Version 0.1.0-Beta)
                </h1>
                <p className='text-xs md:text-sm lg:text-base'>
                  (c) MRZ Corporation. All rights reserved.
                </p>
              </div>
              <div className='flex items-center text-xs mg:text-sm lg:text-base mb-4'>
                <p className='mr-2'>{`C:/Users/mrz>`}</p>
                <TypeAnimation
                  sequence={[COMMAND]}
                  speed={SPEED}
                  cursor={false}
                />
                {index <= COMMAND.length && (
                  <p
                    className={`${index % 2 === 0 ? 'inline-block' : 'hidden'}`}
                  >
                    |
                  </p>
                )}
              </div>
              {index >= COMMAND.length + 1 && (
                <div className='mb-1'>
                  {/* Rowan Cap font */}
                  <pre
                    className='ml-[-8%] md:ml-[-7%] lg:ml-[-3%] text-[.25rem] 
                    md:text-[.35rem] xl:text-[.4rem]'
                  >{`
                   dMMMMMMMMb dMMMMb dMMMMMP        .dMMMb  dMP dMP .dMMMb dMMMMMMP dMMMMMP dMMMMMMMMb .dMMMb 
                   dMP"dMP"dMPdMP.dMP  .dMP"        dMP" VP dMP.dMP dMP" VP   dMP   dMP     dMP"dMP"dMPdMP" VP 
                  dMP dMP dMPdMMMMK" .dMP"          VMMMb   VMMMMP  VMMMb    dMP   dMMMP   dMP dMP dMP VMMMb   
                 dMP dMP dMPdMP"AMF.dMP"          dP .dMP dA .dMP dP .dMP   dMP   dMP     dMP dMP dMPdP .dMP   
                dMP dMP dMPdMP dMPdMMMMMP         VMMMP"  VMMMP"  VMMMP"   dMP   dMMMMMP dMP dMP dMP VMMMP"    
                `}</pre>
                </div>
              )}
              <ul className='[&>*]:mb-[.1rem] last:[&>*]:mb-0'>
                {progressSentences.map((item, i) => {
                  // Don's show 'Complete' message as long as progress
                  // is not at 100%
                  if (i === progressSentences.length - 1 && progress !== 100)
                    return null;

                  return (
                    index >= COMMAND.length + i + 2 && (
                      <li key={`pr-s-${i}`}>
                        <p className='text-xs md:text-sm'>{`[${i + 1}/${
                          progressSentences.length
                        }] ${item}`}</p>
                      </li>
                    )
                  );
                })}
              </ul>
              {progress === 100 &&
                index >= COMMAND.length + progressSentences.length + 3 && (
                  <button
                    className='mt-3 text-white md:text-base lg:mt-3
                  bg-blue-400 px-2 py-1 border-b-4 border-r-4 border-white
                    hover:border-none hover:translate-y-1'
                    onClick={() => setShow(false)}
                  >
                    {`> PRESS TO ENTER`}
                  </button>
                )}
              <div
                className='fixed bottom-2 left-0 flex flex-col w-full px-2 lg:px-4
                text-xs md:text-sm lg:text-base md:flex-row md:justify-between'
              >
                <p className='mb-1 md:mb-0'>
                  Press <b>DEL</b> to enter <b>BIOS</b> settings.
                </p>
                <p>{Date()}</p>
              </div>
            </a.div>
          )
      )}
    </>
  );
};

export default Intro;
