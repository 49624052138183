import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import {
  IntroProvider,
  SoundProvider,
  ThemeProvider,
  RocketProvider,
} from './context';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SoundProvider>
          <RocketProvider>
            <IntroProvider>
              <App />
            </IntroProvider>
          </RocketProvider>
        </SoundProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
