import { useGLTF } from '@react-three/drei';
import { useTheme } from '../context';

const DeskLight = (props) => {
  const { nodes, materials } = useGLTF('/models/desk-light.glb');

  const { theme } = useTheme();

  return (
    <group {...props} dispose={null}>
      <group rotation={[0, 0.357, 0]} scale={0.294}>
        <mesh
          geometry={nodes.Circle018.geometry}
          material={materials['Material.013']}
        />
        <mesh
          geometry={nodes.Circle018_2.geometry}
          material={materials['metal white']}
        />
        <mesh
          geometry={nodes.Circle018_3.geometry}
          material={materials['BLACK PLASTIC']}
        />
        {theme === 'dark' ? (
          <mesh
            geometry={nodes.Circle018_1.geometry}
            material={materials['light - led - red.001']}
          />
        ) : (
          <mesh geometry={nodes.Circle018_1.geometry} />
        )}
      </group>
    </group>
  );
};

export default DeskLight;

useGLTF.preload('/models/desk-light.glb');
