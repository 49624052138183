import { useTheme } from '../../context';
import { pointLights } from '../../constants';

const Lights = () => {
  const { theme } = useTheme();
  const isNight = theme === 'dark';

  return (
    <>
      <directionalLight
        position={[47, 44, 55]}
        intensity={isNight ? 0.3 : 1.4}
      />
      <ambientLight color='#284368' intensity={isNight ? 4.5 : 3.5} />
      {process.env.NODE_ENV === 'production' &&
        pointLights.map(({ position, nightIntensity, dayIntensity }, i) => (
          <pointLight
            key={`p-l-${i}`}
            position={position}
            intensity={isNight ? nightIntensity : dayIntensity}
          />
        ))}
    </>
  );
};

export default Lights;
