import { VolumeX, Volume1 } from 'lucide-react';

import { useSound, useTheme } from '../context';

const SoundToggle = () => {
  const { theme } = useTheme();
  const { isMute, toggleSound } = useSound();

  const classes =
    theme === 'dark' ? 'bg-black border-white' : 'bg-white border-black';
  const iconsClasses = theme === 'dark' ? 'text-white' : 'text-black';

  return (
    <div
      className={`flex justify-center items-center p-2 rounded-full border-2 ${classes}`}
    >
      <button onClick={toggleSound}>
        {isMute ? (
          <Volume1
            className={`w-5 h-5 lg:w-6 lg:h-6 fill-current ${iconsClasses}`}
          />
        ) : (
          <VolumeX
            className={`w-5 h-5 lg:w-6 lg:h-6 fill-current ${iconsClasses}`}
          />
        )}
      </button>
    </div>
  );
};

export default SoundToggle;
