import { createContext, useContext, useState } from 'react';

const soundContext = createContext({
  isMute: false,
  toggleSound: () => {},
});

const SoundProvider = ({ children }) => {
  const [isMute, setIsMute] = useState(false);

  return (
    <soundContext.Provider
      value={{
        isMute,
        toggleSound: () => setIsMute((isMute) => !isMute),
      }}
    >
      {children}
    </soundContext.Provider>
  );
};

const useSound = () => useContext(soundContext);

export { soundContext, SoundProvider, useSound };
