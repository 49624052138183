import { Sun, Moon } from 'lucide-react';

import { useTheme } from '../context';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  const classes =
    theme === 'dark' ? 'bg-black border-white' : 'bg-white border-black';

  return (
    <div
      className={`flex justify-center items-center p-2 rounded-full border-2 ${classes}`}
    >
      <button onClick={toggleTheme}>
        {theme === 'dark' ? (
          <Sun className='w-5 h-5 lg:w-6 lg:h-6  text-white fill-current' />
        ) : (
          <Moon className='w-5 h-5 lg:w-6 lg:h-6  text-black fill-current' />
        )}
      </button>
    </div>
  );
};

export default ThemeToggle;
