import { createContext, useContext, useState } from 'react';

const introContext = createContext({
  isRemoved: false,
  setRemoved: () => {},
});

const IntroProvider = ({ children }) => {
  const [isRemoved, setIsRemoved] = useState(
    process.env.NODE_ENV === 'development'
  );

  const handleRemove = () => setIsRemoved(true);

  return (
    <introContext.Provider
      value={{
        isRemoved,
        setRemoved: handleRemove,
      }}
    >
      {children}
    </introContext.Provider>
  );
};

const useIntro = () => useContext(introContext);

export { introContext, IntroProvider, useIntro };
