import { ThemeToggle, SoundToggle } from '.';

const Header = () => {
  return (
    <div className='fixed top-2 right-1 flex gap-x-2 lg:right-2 z-50'>
      <ThemeToggle />
      <SoundToggle />
    </div>
  );
};

export default Header;
