import { memo, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';

import { decideServerLights } from '../utils';

const Server = (props) => {
  const { nodes, materials } = useGLTF('/models/server.glb');
  const [firstCluster, setFirstCluster] = useState('#ff6c70');
  const [secondCluster, setSecondCluster] = useState('gray');
  const [thirdCluster, setThirdCluster] = useState('#F7B500');

  useEffect(() => {
    const interval = setInterval(() => {
      const [first, second, third] = decideServerLights();
      setFirstCluster(first);
      setSecondCluster(second);
      setThirdCluster(third);
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <group {...props} dispose={null}>
      <group position={[0, 2.181, 0]}>
        <mesh geometry={nodes.Plane023.geometry}>
          <meshPhongMaterial
            toneMapped={false}
            emissive={firstCluster}
            emissiveIntensity={firstCluster === 'gray' ? 0 : 4}
          />
        </mesh>
        <mesh geometry={nodes.Plane023_1.geometry}>
          <meshPhongMaterial
            toneMapped={false}
            emissive={secondCluster}
            emissiveIntensity={secondCluster === 'gray' ? 0 : 4}
          />
        </mesh>
        <mesh geometry={nodes.Plane023_2.geometry}>
          <meshPhongMaterial
            toneMapped={false}
            emissive={thirdCluster}
            emissiveIntensity={thirdCluster === 'gray' ? 0 : 4}
          />
        </mesh>
        <mesh
          geometry={nodes.Plane023_3.geometry}
          material={materials.PaletteMaterial002}
        />
      </group>
    </group>
  );
};

export default memo(Server);

useGLTF.preload('/models/server.glb');
