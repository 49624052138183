import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

const Globe = (props) => {
  const { nodes, materials } = useGLTF('/models/globe.glb');

  const ref = useRef();

  useFrame((_, delta) => {
    ref.current.rotation.y += delta * 2;
  });

  return (
    <group {...props} dispose={null} ref={ref}>
      <mesh
        geometry={nodes.Sphere.geometry}
        material={materials['PC PLASTIC 02']}
        rotation={[-0.306, -0.268, -0.495]}
        scale={0.315}
      />
    </group>
  );
};

export default Globe;

useGLTF.preload('/models/globe.glb');
