import { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF, useAnimations } from '@react-three/drei';

import { useIntro } from '../context';

const Person = (props) => {
  const groupRef = useRef();
  const { nodes, materials, animations } = useGLTF('/models/person.glb');
  const { actions, names } = useAnimations(animations, groupRef);

  const { isRemoved } = useIntro();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    if (!isRemoved) return;

    names.forEach((name) =>
      actions[name].setDuration(3).setLoop(null, 1).play()
    );

    // Pause the animations before the avatar goes back to the
    // default position
    const timeout = setTimeout(() => {
      names.forEach((name) => (actions[name].paused = true));
    }, 2800);

    return () => clearTimeout(timeout);
  }, [isRemoved, names, actions]);

  // Make the model invisible when the camera gets too near, so
  // it does not pass through the model
  useFrame(({ camera }) => {
    if (!groupRef.current) return;

    const distanceX = camera.position.x - groupRef.current.position.x;
    const distanceZ = camera.position.z - groupRef.current.position.z;
    if (
      distanceX <= 0.75 &&
      distanceX >= -0.75 &&
      distanceZ <= 0.75 &&
      distanceZ >= -0.75
    ) {
      groupRef.current.visible = false;
    } else {
      groupRef.current.visible = true;
    }
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <group name='Scene'>
        <group
          name='Plane023'
          position={[0.015, -0.205, 0.056]}
          rotation={[0, -0.867, 0]}
        >
          <mesh
            name='Plane014'
            geometry={nodes.Plane014.geometry}
            material={materials.PaletteMaterial004}
          />
          <mesh
            name='Plane014_1'
            geometry={nodes.Plane014_1.geometry}
            material={materials.PaletteMaterial004}
          />
          <group
            name='Armature001'
            position={[0.081, 2.176, -0.069]}
            rotation={[-3.03, -1.031, -3.043]}
            scale={1.935}
          >
            <primitive object={nodes.Bone} />
            <primitive object={nodes.FOOTikL} />
            <primitive object={nodes.LEGikR} />
            <primitive object={nodes.neutral_bone} />
          </group>
          <group name='Cube003'>
            <skinnedMesh
              name='Cube001'
              geometry={nodes.Cube001.geometry}
              material={materials['skin.001']}
              skeleton={nodes.Cube001.skeleton}
            />
            <skinnedMesh
              name='Cube001_1'
              geometry={nodes.Cube001_1.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_1.skeleton}
            />
            <skinnedMesh
              name='Cube001_2'
              geometry={nodes.Cube001_2.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_2.skeleton}
            />
            <skinnedMesh
              name='Cube001_3'
              geometry={nodes.Cube001_3.geometry}
              material={nodes.Cube001_3.material}
              skeleton={nodes.Cube001_3.skeleton}
            />
            <skinnedMesh
              name='Cube001_4'
              geometry={nodes.Cube001_4.geometry}
              material={materials.PaletteMaterial002}
              skeleton={nodes.Cube001_4.skeleton}
            />
            <skinnedMesh
              name='Cube001_5'
              geometry={nodes.Cube001_5.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_5.skeleton}
            />
            <skinnedMesh
              name='Cube001_6'
              geometry={nodes.Cube001_6.geometry}
              material={materials.PaletteMaterial003}
              skeleton={nodes.Cube001_6.skeleton}
            />
            <skinnedMesh
              name='Cube001_7'
              geometry={nodes.Cube001_7.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_7.skeleton}
            />
            <skinnedMesh
              name='Cube001_8'
              geometry={nodes.Cube001_8.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_8.skeleton}
            />
            <skinnedMesh
              name='Cube001_9'
              geometry={nodes.Cube001_9.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_9.skeleton}
            />
            <skinnedMesh
              name='Cube001_10'
              geometry={nodes.Cube001_10.geometry}
              material={materials.PaletteMaterial003}
              skeleton={nodes.Cube001_10.skeleton}
            />
            <skinnedMesh
              name='Cube001_11'
              geometry={nodes.Cube001_11.geometry}
              material={materials.eye}
              skeleton={nodes.Cube001_11.skeleton}
            />
            <skinnedMesh
              name='Cube001_12'
              geometry={nodes.Cube001_12.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_12.skeleton}
            />
            <skinnedMesh
              name='Cube001_13'
              geometry={nodes.Cube001_13.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_13.skeleton}
            />
          </group>
        </group>
      </group>
    </group>
  );
};

export default Person;

useGLTF.preload('/models/person.glb');
