import { useGLTF, Plane, Html } from '@react-three/drei';

import { OS_ENDPOINT } from '../constants';

const CenterMonitor = (props) => {
  const { nodes, materials } = useGLTF('/models/center-monitor.glb');

  return (
    <group {...props} dispose={null}>
      <group scale={0.85}>
        <mesh
          geometry={nodes.Plane025.geometry}
          material={materials['PC PLASTIC']}
        />
      </group>
      <Plane
        args={[1.85, 0.95]}
        position={[-0.005, 0.71, -0.04]}
        rotation-y={Math.PI * 0.9795}
      >
        <meshBasicMaterial color='black' />
        <Html
          transform
          occlude='blending'
          distanceFactor={1}
          position={[0, 0, 0.01]}
          className='w-[750px] h-[390px]'
          zIndexRange={[2, 10]}
        >
          <iframe
            title='embed'
            src={`${OS_ENDPOINT}/${props.path || ''}`}
            className='w-full h-full'
          ></iframe>
        </Html>
      </Plane>
    </group>
  );
};

export default CenterMonitor;

useGLTF.preload('/models/center-monitor.glb');
