import { useRef, useEffect, useState, useMemo } from 'react';
import { Phone, FileCode2 } from 'lucide-react';
import { useTransition, animated as a } from '@react-spring/web';

import { Backdrop } from '../../components';
import { LaunchForm } from '../components';

const Controller = ({ onContact, onOrder }) => {
  const AnimatedLaunchForm = useMemo(() => a(LaunchForm), []);
  const AnimatedBackdrop = useMemo(() => a(Backdrop), []);

  const wrapperRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (!wrapperRef.current || !imgRef.current) return;
    const wrapper = wrapperRef.current;
    const img = imgRef.current;

    let timeout;
    const handleClick = () => {
      img.style.opacity = 0;
      wrapper.style.transform = 'translate(-50%,-62.5%)';

      setTimeout(() => {
        img.style.opacity = 1;
        wrapper.style.transform = 'translate(-50%, -75%)';
      }, 200);
    };

    wrapper.addEventListener('click', handleClick);

    return () => {
      wrapper.removeEventListener('click', handleClick);
      clearTimeout(timeout);
    };
  });

  const [isLaunchFormOpen, setIsLaunchFormOpen] = useState(false);

  const launchFormTransition = useTransition(isLaunchFormOpen, {
    from: { y: -1000, transform: 'translate(-50%, -50%)' },
    enter: { y: 0, transform: 'translate(-50%, -50%)' },
    leave: { y: -1000, transform: 'translate(-50%, -50%)' },
  });

  const backdropTransition = useTransition(isLaunchFormOpen, {
    from: { opacity: 0 },
    enter: { opacity: 0.7 },
    leave: { opacity: 0 },
  });

  const handleLaunchFormOpen = () => setIsLaunchFormOpen(true);

  const handleLaunchFormClose = () => setIsLaunchFormOpen(false);

  return (
    <div className='absolute bottom-5 left-1/2 translate-x-[-50%] z-50'>
      {backdropTransition(
        (style, item) =>
          item && (
            <AnimatedBackdrop style={style} onClick={handleLaunchFormClose} />
          )
      )}
      {launchFormTransition(
        (style, item) =>
          item && (
            <AnimatedLaunchForm style={style} onClose={handleLaunchFormClose} />
          )
      )}
      <div className='w-56 h-[2.8rem] lg:w-80 lg:h-16 '>
        <div className='w-full h-full absolute top-0 left-0 z-[-1]'>
          <img
            src='/images/launch-bg.png'
            alt='Launch Bg'
            className='w-full h-full'
          />
        </div>
        <div
          className='w-14 h-14 rounded-full absolute top-1/2 left-1/2 translate-x-[-50%]
          translate-y-[-50%] lg:w-20 lg:h-20'
        >
          <img
            src='/images/launch-btn-bg.png'
            alt='Launch Btn Bg'
            className='w-full h-full'
          />
        </div>
      </div>
      <div
        className='w-11 h-11 rounded-full absolute top-1/2 left-1/2 translate-x-[-50%]
        translate-y-[-75%] lg:w-16 lg:h-16 hover:cursor-pointer z-[1] duration-[50ms]'
        onClick={handleLaunchFormOpen}
        ref={wrapperRef}
      >
        <img
          ref={imgRef}
          src='/images/launch-btn.png'
          alt='Lauch'
          className='absolute top-0 left-0 duration-[50ms]'
        />
        <img
          src='/images/launch-btn-pressed.png'
          alt='Pressed'
          className='absolute top-0 left-0'
        />
      </div>
      <div
        className='w-[2.85rem] h-11 rounded-full absolute top-1/2 left-1/2 translate-x-[-50%]
        translate-y-[-58%] lg:w-[4.1rem] lg:h-16 bg-black'
      />
      <div
        className='absolute top-1/2 left-[20%] w-14 h-6 translate-y-[-50%]
        translate-x-[-50%] flex justify-center items-center gap-x-[.4rem]
        lg:w-20 cursor-pointer'
        onClick={onContact}
      >
        <Phone className='text-white w-3 h-3 lg:w-5 lg:h-5' />
        <p className='font-kode text-white text-[.5rem] lg:text-[.6rem]'>
          Contact
        </p>
      </div>
      <div
        className='absolute top-1/2 left-[80%] w-14 h-6 translate-y-[-50%]
        translate-x-[-50%] flex justify-center items-center gap-x-[.4rem]
        lg:w-20 cursor-pointer'
        onClick={onOrder}
      >
        <FileCode2 className='text-white w-3 h-3 lg:w-5 lg:h-5' />
        <p className='font-kode text-white text-[.5rem] lg:text-xs'>Order</p>
      </div>
    </div>
  );
};

export default Controller;
