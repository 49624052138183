import { useEffect, useState } from 'react';
import { Sphere } from '@react-three/drei';

const AntenaLight = (props) => {
  const [on, setOn] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setOn((on) => !on), 1000);
    return () => clearInterval(interval);
  });

  return (
    <Sphere {...props}>
      <meshPhongMaterial
        toneMapped={false}
        emissive={on ? '#fa0000' : 'gray'}
        emissiveIntensity={on ? 5 : 0}
      />
    </Sphere>
  );
};

export default AntenaLight;
