import { useEffect, memo } from 'react';
import { useGLTF } from '@react-three/drei';
import { useSpring, animated as a } from '@react-spring/three';

const RadarTwo = (props) => {
  const { nodes, materials } = useGLTF('/models/radar-two.glb');

  const [spring, api] = useSpring(() => ({
    'rotation-y': 0,
    config: { friction: 40 },
  }));

  useEffect(() => {
    let timeout;

    const wander = () => {
      const rnd = Math.random();

      api.start({ 'rotation-y': rnd > 0.5 ? rnd : -rnd });
      timeout = setTimeout(wander, (1 + Math.random() * 2) * 800);
    };
    wander();

    return () => clearTimeout(timeout);
  });

  return (
    <a.group {...props} dispose={null} {...spring}>
      <mesh
        geometry={nodes.Sphere001.geometry}
        material={materials['PC PLASTIC.001']}
        rotation={[0, 0, 0.796]}
        scale={0.606}
      />
    </a.group>
  );
};

export default memo(RadarTwo);

useGLTF.preload('/models/radar-two.glb');
