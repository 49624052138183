import { Text } from '@react-three/drei';
import { animated as a, useSpring } from '@react-spring/three';

const AnimatedText = a(Text);

const SignText = (props) => {
  const [spring, api] = useSpring(() => ({
    from: { scale: 1, color: '#fff' },
  }));

  const handlePointerEnter = () =>
    api({
      to: { scale: 0.8, color: '#ccc' },
    });

  const handlePointerLeave = () =>
    api({
      to: { scale: 1, color: '#fff' },
    });

  return (
    <AnimatedText
      font='/fonts/Lato-Bold.ttf'
      {...props}
      {...spring}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {props.children}
    </AnimatedText>
  );
};

export default SignText;
