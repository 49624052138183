import { useGLTF, Image } from '@react-three/drei';

const RocketMonitorTwo = (props) => {
  const { nodes, materials } = useGLTF('/models/rocket-monitor-two.glb');

  return (
    <group {...props} dispose={null}>
      <group rotation={[0, 0, -Math.PI / 2]} scale={0.097}>
        <mesh
          geometry={nodes.Plane030.geometry}
          material={materials['BLACK PLASTIC.003']}
        />
      </group>
      <group rotation-y={Math.PI * 0.5} position={[0, 1, 1.6]}>
        <Image url='/images/gold-chart.png'>
          <planeGeometry args={[2, 4]} />
        </Image>
      </group>
    </group>
  );
};

export default RocketMonitorTwo;

useGLTF.preload('/models/rocket-monitor-two.glb');
