import { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { animated as a, useSpring, easings } from '@react-spring/three';

import { Fire } from '../shaders';
import { useRocket } from '../context/rocket';

const Rocket = (props) => {
  const { nodes, materials } = useGLTF('/models/rocket.glb');
  const [spring, api] = useSpring(() => ({
    from: { 'position-y': 0 },
  }));
  const ref = useRef();

  const { isLaunching, isLanding, setLaunching, setLanding, setLanded } =
    useRocket();

  useEffect(() => {
    if (!isLaunching) return;

    api.start({
      to: { 'position-y': 100 },
      config: { duration: 12000, easing: easings.easeInCubic },
      delay: 5000,
      onStart: () => setLanded(false),
      onResolve: () => {
        setLaunching(false);
        setLanding(true);
      },
    });
  }, [isLaunching, api, setLanding, setLaunching, setLanded]);

  useEffect(() => {
    if (!isLanding) return;
    if (!ref.current) return;

    api.start({
      from: { 'position-y': ref.current.position.y },
      to: { 'position-y': 0 },
      config: { duration: 6000, easing: easings.easeOutCubic },
      onResolve: () => {
        setLanding(false);
        setLanded(true);
      },
    });
  }, [isLanding, api, setLanding, setLanded]);

  return (
    <a.group {...props} dispose={null} {...spring} ref={ref}>
      <group position={[1.63, 7.987, -0.005]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Circle010.geometry}
          material={materials['light - rocket']}
        />
        <mesh
          geometry={nodes.Circle010_1.geometry}
          material={materials['Material.004']}
        />
      </group>
      <group position={[-0.001, 0, -0.005]} scale={1.72}>
        <mesh
          geometry={nodes.Circle007_1.geometry}
          material={materials['metal - white']}
        />
        <mesh
          geometry={nodes.Circle007_2.geometry}
          material={materials['metal - orange']}
        />
      </group>
      <group rotation-x={Math.PI} position={[0, -3.5, 0]}>
        <Fire scale={[3, 7, 3]} visible={isLaunching || isLanding} />
      </group>
    </a.group>
  );
};

export default Rocket;

useGLTF.preload('/models/rocket.glb');
