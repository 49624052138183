import { Radar, RadarTwo } from '.';

const Radars = () => {
  return (
    <group>
      <Radar
        position={[14.16, 2.15, 27.8]}
        scale={0.23}
        rotation-z={-Math.PI * 0.3}
      />
      <Radar
        position={[-3.075, 4.2, -10.85]}
        rotation-z={-Math.PI * 0.4}
        scale={0.75}
      />
      <RadarTwo
        position={[14.0, 3.375, 28.15]}
        rotation-z={-Math.PI * 0.25}
        scale={1}
      />
      <RadarTwo
        position={[14.3, 2.85, 29.75]}
        rotation-z={-Math.PI * 0.4}
        scale={0.5}
      />
      <RadarTwo position={[-3.82, 8.5, -11.05]} rotation-x={-Math.PI * 0.4} />
      <RadarTwo
        position={[-5.95, 9.9, -10.6]}
        rotation-z={Math.PI * 0.5}
        scale={1.2}
      />
    </group>
  );
};

export default Radars;
