const BackButton = ({ onClick }) => {
  return (
    <button
      className='absolute top-0 left-0 w-24 h-16 z-50 lg:w-28 lg:h-[4.5rem]'
      onClick={onClick}
    >
      <div className='w-full h-full'>
        <img src='/images/back.png' alt='Back' className='w-full h-full' />
      </div>
      <p
        className='absolute text-white top-1/2 right-[15%] translate-y-[-50%] 
        font-semibold lg:text-lg font-kode'
      >
        Back
      </p>
    </button>
  );
};

export default BackButton;
