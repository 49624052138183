import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '../constants';

const useLanding = () =>
  useQuery({
    queryKey: ['landing'],
    queryFn: () => fetch(`${ENDPOINT}/api/landing`).then((res) => res.json()),
  });

const mutateLaunch = (name, email, mobile) => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  formData.append('mobile', mobile);

  return fetch(`${ENDPOINT}/api/flight`, {
    method: 'POST',
    body: formData,
  });
};

export { useLanding, mutateLaunch };
