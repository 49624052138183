import { useEffect, useState, memo } from 'react';

import { GroundLight } from '.';
import { groundLights } from '../constants';
import { useRocket } from '../context';

const GroundLights = () => {
  const [on, setOn] = useState(false);
  const { isLaunching, isLanding } = useRocket();

  useEffect(() => {
    if (!isLaunching && !isLanding) return setOn(true);

    const interval = setInterval(() => setOn((on) => !on), 1000);
    return () => clearInterval(interval);
  }, [isLaunching, isLanding]);

  return (
    <group>
      {groundLights.map((position, i) => (
        <GroundLight key={`ground-light-${i}`} position={position} on={on} />
      ))}
    </group>
  );
};

export default memo(GroundLights);
