import { createPortal } from 'react-dom';
import { forwardRef } from 'react';

const Backdrop = forwardRef(({ onClick }, ref) => {
  return createPortal(
    <div
      ref={ref}
      onClick={onClick}
      className='fixed top-0 left-0 w-full h-screen bg-black z-[100]'
    />,
    document.getElementById('root')
  );
});

export default Backdrop;
