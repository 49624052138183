import { BackSide } from 'three';
import { Sky as DreiSky, Stars, Sphere } from '@react-three/drei';
import { LayerMaterial, Gradient } from 'lamina';

import { Clouds } from '../../models';
import { useTheme } from '../../context';

const Sky = () => {
  const { theme } = useTheme();
  const isNight = theme === 'dark';

  const x = isNight ? 50 : 100;
  const y = isNight ? -1 : 10;

  if (!isNight)
    return (
      <>
        <DreiSky sunPosition={[x, y, 100]} distance={1500} />
        <Clouds />
      </>
    );
  return (
    <>
      <Sphere scale={1500}>
        <LayerMaterial side={BackSide} fog={false}>
          <Gradient colorA='#111' colorB='#222' axes='y' start={1} end={-3} />
        </LayerMaterial>
      </Sphere>
      <Clouds />
      <Stars
        radius={250}
        depth={40}
        count={5000}
        factor={7}
        saturation={0}
        fade
        speed={1.2}
      />
    </>
  );
};

export default Sky;
