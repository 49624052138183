import { useGLTF, Float } from '@react-three/drei';

const Clouds = (props) => {
  const { nodes, materials } = useGLTF('/models/clouds.glb');

  return (
    <Float speed={0.65}>
      <group {...props} dispose={null} position={[0, 28, -10]}>
        <mesh
          geometry={nodes.Mball028.geometry}
          material={materials['Material.003']}
          position={[-2.121, 0.808, -6.908]}
          scale={[3, 2.2, 3]}
        />
      </group>
    </Float>
  );
};

export default Clouds;

useGLTF.preload('/models/clouds.glb');
