import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

const Fan = (props) => {
  const ref = useRef();
  const { nodes, materials } = useGLTF('/models/fan.glb');

  useFrame((_, delta) => {
    ref.current.rotation.x += delta * 10;
  });

  return (
    <group {...props} dispose={null} ref={ref}>
      <mesh
        geometry={nodes.Plane030.geometry}
        material={materials['METAL CHAIR.001']}
        rotation={[-Math.PI, 1.458, -Math.PI]}
        scale={1.031}
      />
    </group>
  );
};

export default Fan;

useGLTF.preload('/models/fan.glb');
