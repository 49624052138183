import { useGLTF, Image, Html } from '@react-three/drei';

const RocketMonitorOne = (props) => {
  const { nodes, materials } = useGLTF('/models/rocket-monitor-one.glb');

  return (
    <group {...props} dispose={null}>
      <group rotation={[0, 0, -Math.PI / 2]} scale={0.097}>
        <mesh
          geometry={nodes.Plane001_1.geometry}
          material={materials['BLACK PLASTIC.001']}
        />
        <mesh
          geometry={nodes.Plane001_3.geometry}
          material={materials['light - led - red.002']}
        />
      </group>
      <group rotation-y={Math.PI * 0.5} position={[0, 0.9, 1.15]}>
        <Image url='/images/bitcoin-chart.png'>
          <planeGeometry args={[2.5, 1.6]} />
        </Image>
      </group>
    </group>
  );
};

export default RocketMonitorOne;

useGLTF.preload('/models/rocket-monitor-one.glb');
